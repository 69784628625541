<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-between flex-wrap my-1">
        <h4>{{ $t("settings.menu.title") }}</h4>
        <div class="d-flex">
          <b-button
            v-b-modal.modal-custom-my-account-menu
            variant="outline-primary"
            class="ml-1"
          >
            {{ $t("backoffice.my-account-menu.modal-title") }}
          </b-button>
          <b-button variant="primary" class="ml-1" @click="saveStyle">
            {{ $t("form.actions.save") }}
          </b-button>
        </div>
      </div>
      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="$t('settings.menu.type')"
            label-for="layout"
            class="w-100"
          >
            <v-select
              id="layout"
              v-model="menuType"
              :clearable="false"
              :reduce="(place) => place.value"
              :options="menuOptions"
              label="text"
            />
          </b-form-group>
          <span class="font-italic mt-1 disclaimer">
            {{ $t("settings.menu.advice") }}
          </span>
        </b-col>
      </b-row>
      <modal-my-account-menu-custom />
    </b-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import ModalMyAccountMenuCustom from "@/backoffice/components/ModalMyAccountMenuCustom.vue";

export default {
  name: "Menu",
  components: {
    vSelect,
    ModalMyAccountMenuCustom,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      menuType: "horizontal",
    };
  },
  computed: {
    menuOptions() {
    return [
    { text: this.$t("settings.menu.horizontal"), value: "horizontal" },
    { text: this.$t("settings.menu.vertical"), value: "vertical" },
    ];
  },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
  },
  created() {
    if (this.currentCollective.menu?.layout) {
      this.menuType = this.currentCollective.menu.layout;
    }
  },
  methods: {
    async saveStyle() {
      try {
        if (Array.isArray(this.currentCollective.menu)) {
          this.currentCollective.menu = { ...this.currentCollective.menu };
        }
        if (!this.currentCollective.menu) {
          this.currentCollective.menu = {};
        }
        this.currentCollective.menu.layout = this.menuType;
        await this.$store.dispatch("editItem", {
          noSet: true,
          item: {
            itemType: "communityCustoms",
            requestConfig: {
              menu: this.currentCollective.menu,
            },
          },
        });

        this.notifySuccess(this.$t("backoffice.settings.messages.success"));
      } catch (e) {
        console.error(e);
        this.notifyError(this.$t("backoffice.settings.messages.error"));
      }
    },
  },
};
</script>

<style>
.disclaimer {
  font-size: 0.8em;
}
</style>
