<template>
  <b-modal
    id="modal-custom-my-account-menu"
    :title="$t('backoffice.my-account-menu.modal-title')"
    size="lg"
    centered
    hide-footer
    @show="openModal"
  >
    <div class="mt-2 d-flex flex-column justify-content-center">
      <span
        class="b-avatar shadow mb-2 rounded-circle d-block mx-auto"
        :class="`badge-primary bg-light-primary`"
        style="width: 50px; height: 50px"
      >
        <b-icon
          icon="person-lines-fill"
          font-scale="2"
          class="mt-75"
          :class="`text-primary`"
        />
      </span>
      <h5 class="mb-2 px-2 text-center">
        {{ $t("backoffice.my-account-menu.title") }}
      </h5>
      <b-form class="mb-2" @submit.prevent="handleSaveMenu">
        <b-row class="p-2 px-lg-3">
          <b-col
            v-for="(menuItem, index) of myselfMenuItems"
            :key="index"
            cols="6"
            lg="4"
            class="mb-1"
          >
            <b-form-checkbox v-model="menu[menuItem.code]">
              {{ menuItem.title }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-button
          variant="primary"
          class="float-right mr-50 mt-2"
          :disabled="isSending"
          type="submit"
        >
          <span
            v-if="isSending"
            class="d-flex"
          ><b-spinner
             class="mr-1"
             small
             variant="white"
             label="Loading..."
           />
            {{ $t("form.actions.saving") }}
          </span>
          <span v-else>{{ $t("form.actions.save") }}</span>
        </b-button>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      isSending: false,
      menu: {},
      initMenu: {
        dashboard: true,
        settings: true,
        passport: true,
        notifications: true,
        payments: true,
        roles: true,
        spaces: true,
        calendar: true,
        messages: true,
        chat: true,
        tickets: true,
        connections: true,
        referrals: true,
        likes: false,
      },
    };
  },
  computed: {
    myselfMenuItems() {
      return [
        {
          code: "dashboard",
          title: this.$t("backoffice.dashboard.title"),
        },
        {
          code: "passport",
          title: this.$t("backoffice.passport"),
        },
        {
          code: "notifications",
          title: this.$t("backoffice.notifications.title"),
        },
        {
          code: "roles",
          title: this.$t("backoffice.roles.my-roles"),
        },
        {
          code: "spaces",
          title: this.$t("settings.my-communities.title"),
        },
        {
          code: "calendar",
          title: this.$t("calendar.title"),
        },
        {
          code: "messages",
          title: this.$t("messages.title"),
        },
        {
          code: "chat",
          title: this.$t("chat.title"),
        },
        {
          code: "payments",
          title: this.$t("members.payments.title"),
        },
        {
          code: "orders",
          title: this.$t("tickets.title"),
        },
        {
          code: "connections",
          title: this.$t("members.member-detail.connections"),
        },
        {
          code: "referrals",
          title: this.$t("backoffice.referrals.title"),
        },
        {
          code: "likes",
          title: this.$t("myself.menu.likes"),
        },
      ];
    },
    communityCustomizations() {
      return this.$store.getters.communityCustomizations;
    },
    menuMyAccount() {
      if (this.communityCustomizations?.menu_my_account) {
        return this.communityCustomizations?.menu_my_account;
      }
      return this.initMenu;
    },
  },
  async created() {
    this.menu = this.initMenu;
  },
  methods: {
    async openModal() {
      this.menu = {};
      this.menu = { ...this.menuMyAccount };
    },
    async fetchData() {
      await this.$store.dispatch("getCommunityCustomizations");
    },
    async handleSaveMenu() {
      await this.$store.dispatch("editItem", {
        noSet: true,
        item: {
          itemType: "communityCustomizations",
          requestConfig: {
            menuMyAccount: {
              ...this.menu,
            },
          },
        },
      });
      this.fetchData();
      this.$bvModal.hide("modal-custom-my-account-menu");
    },
  },
};
</script>

<style></style>
